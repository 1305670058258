<template lang="pug">
div(class='VideoReviews')
	h2 {{ variablesList.videoReviewsTitle }}
	div(class='Link')
		UiButton(
			:href='variablesList.videoReviewsLink',
			target='_blank',
			size='md',
			variant='secondary',
			iconRight='system/arrow-top-right',
			iconRightSize='sm',
			rounded
		) Посмотреть все

	SliderReviews(class='SliderVideoReviews', :reviews='reviewsState', video)
</template>

<script lang="ts" setup>
/**
 * Define hooks
 */
const { variablesList, fetchVariables } = useVariables();
const { reviewsState, fetchReviews } = useReviews(true);

/**
 * Define fetch
 */
await fetchVariables();
await fetchReviews();
</script>

<style lang="less" scoped>
.VideoReviews {
	.box(grid; 100%; auto; none; 1.5rem; auto; 100%; center; start);

	& > h2 {
		.grid(1 span);
	}
	& > .Link {
		.grid(3 4; 1 span);
	}
	& > .SliderVideoReviews {
		.grid(2 3; 1 span);
	}

	@media all and (min-width: @tablet-small) {
		.box(grid; 100%; auto; none; 1.5rem; auto; 60% 1fr; center; start);
		& > .Link {
			.grid(1 2; 2 3; center; end);
		}
		& > .SliderVideoReviews {
			.grid(2 3; 1 3);
		}
	}
}
</style>
